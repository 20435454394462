/* Marquee */
/* .marqueeText {
  padding-top: 35%;
  -moz-animation: marquee 15s linear infinite;
  -webkit-animation: marquee 15s linear infinite;
  animation: marquee 15s linear infinite;
}
@-moz-keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -100%);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -100%);
  }
}
@keyframes marquee {
  0% {
    -moz-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -moz-transform: translate(0, -100%);
    -webkit-transform: translate(0, -100%);
    transform: translate(0, -100%);
  }
} */

/* INCIDENT GRID */
.issueGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  padding-inline: 5rem;
  row-gap: 5rem;
  text-align: center;
  margin-top: 2.4rem;
}
.selectIssue {
  font-size: 2.5rem;
  color: #333333;
}
.issueText {
  display: block;
  font-size: 1.8rem;
  color: #333333;
}
.issueItem {
  cursor: pointer;
}
/* Social Buttons Container */
.social-container {
  /* margin-top: 2rem; */
  border: 2px solid #444;
  border-radius: 12px;
  padding: 1.2rem;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  background-color: #fff;
}
.social-logo {
  width: 70% !important;
  display: block;
  /* margin: 0 auto; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.4rem;
}
.social-icons {
  height: 40px !important;
  width: 40px !important;
  margin-right: -10px;
}
.social-text {
  display: block;
  margin-top: 2rem;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
}
.social-button-container:link,
.social-button-container:visited {
  text-decoration: none;
}
.social-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #999;
  padding: 0.2rem 2rem;
  border-radius: 100px;
  margin-top: 2rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.social-button-text {
  font-size: 1.8rem;
  color: #000;
  font-weight: 600;
}
.social-button-container:last-child {
  margin-bottom: 4.5rem;
}
/*  */

.grid-column-spread {
  grid-column: 1/3;
}
.slider {
  padding: 0 !important;
  flex-grow: 1;
  margin: 0 !important;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}
.slider-value {
  /* margin-top: 7px; */
  margin-left: 12px;
  font-size: 1.8rem;
}
.slider-container {
  display: flex !important;
  background-color: #f9f9f9;
  font-size: 1.8rem;
  padding: 1.2rem;
  margin-bottom: 3rem;
  width: 45%;
  border-radius: 25px;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08);
}
/* Data Inquiry */
.inquiry-heading {
  color: #333333;
  font-size: 2.5rem;
}

/* Home Screen */
.info-container {
  grid-column: 1/3;
}
.info-text {
  font-size: 1.5rem;
  font-weight: 500;
}
.graph {
  width: 100%;
  border: 2px solid #333;
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.homescreen-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2.2rem;
  column-gap: 2.2rem;
}
.image-heading-container {
  color: #fff;
  /* background-color: #0066cb; */
  background-color: #333;
  text-align: center;
  padding: 1.2rem 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  /* height: 75px; */
}
.graph-heading {
  font-size: 2rem;
  margin-top: auto;
  margin-bottom: auto;
}
.temp-subheading {
  font-size: 1.4rem;
  display: inline;
}
.mobile-report-incident:link,
.mobile-report-incident:visited {
  font-size: 1.5rem;
  text-decoration: none;
  color: #fff;
  background-color: #0066cb;
  padding: 1.2rem 2.4rem;
  display: inline-block;
  /* display: none; */
  border-radius: 25px;
  font-weight: 600;
  margin-bottom: 1.2rem;
  grid-column: 1/3;
}
.mobile-report-incident-1:link,
.mobile-report-incident-1:visited {
  display: none;
}
.sample-graph {
  color: yellow;
  display: inline;
  font-size: 1.4rem;
  font-weight: 550;
}
.cta-container {
  margin-bottom: 2.4rem;
  border: 2px solid #444;
  text-align: center;
}

/* Silent bidding form */
.sbf-area {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 2.4rem;
  border: 2px solid #444;
}
.bidding-area label,
.feedback-form-area label {
  color: #333333;
  font-size: 2rem;
  margin-bottom: 1.2rem;
  display: block;
}
.bidding-area input,
.feedback-form-area textarea {
  background-color: #f9f9f9;
  font-size: 1.8rem;
  display: block;
  padding: 1.2rem;
  margin-bottom: 3rem;
  width: 45%;
  border-radius: 25px;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08);
}

/* Feedback form Area */
.feedback-form-area textarea {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0);
  border: solid #666 1px;
}
.feedback-submit-button {
  padding: 1rem 2rem;
  background-color: #0066cb;
  border-radius: 25px;
  color: #fff;
  font-size: 1.7rem;
  border: none;
}

.button-primary {
  display: inline-block;
  padding: 1rem 2rem;
  text-decoration: none;
  background-color: #0066cb;
  border-radius: 25px;
  color: #fff;
  font-size: 1.6rem;
  cursor: pointer;
}
.section-report-incident {
  min-height: 70vh;
}
.subheading {
  color: #ddd;
  font-size: 1.9rem;
}
.rpa-info .subheading {
  margin-top: 1.2rem;
}
.rpa-info ul,
.rpa-info p {
  color: #ddd;
  margin: 1rem 1rem 0;
  font-size: 1.6rem;
  line-height: 1.8;
}
.incident-flex {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 6.4rem;
  margin-bottom: 4.8rem;
  row-gap: 3.2rem;
}
.rpa-info {
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.rpa-info img {
  width: 100%;
  display: block;
  margin-bottom: 1.2rem;
}

.form-area {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 2.4rem;
  border: 2px solid #444;
}
.incident-form {
  /* margin-top: 2.4rem; */
}
.icon-label {
  color: #333333;
  font-size: 2rem;
  position: relative;
  display: flex;
  gap: 0.8rem;
  justify-content: space-between;
  width: 45%;
}
.popup {
  display: inline-block;
  border: solid #aaa;
  color: #aaa;
  font-size: 1.6rem;
  border-radius: 50%;
  width: 2.3rem;
  text-align: center;
  font-style: italic;
  font-weight: 700;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.popup-hide {
  position: relative;
  display: none;
  z-index: 10;
  width: 350px;
}
.popup:hover + .popup-hide {
  display: block;
  position: absolute;
  left: 110%;
  top: 0;
}
.label-name {
  margin-left: 9px;
}
.tooltip_list_item,
.tooltip_text {
  font-size: 1.8rem !important ;
}
.tooltip_list {
  margin-left: 35px;
  margin-block: 10px;
}
/* Popup Test start */
.popup-hide {
  height: auto;
  padding: 12px 10px;
  font-family: Arial;
  background-color: rgb(241, 241, 241);
  border-radius: 10px;
  margin: 50= px;
}
.popup-hide p {
  padding: 0px;
  margin: 0px;
  font-size: 12px;
}
.arrow-left {
  position: absolute;
  width: 90px;
  height: 50px;
  left: 26px;
  background: rgb(241, 241, 241);
  transform: rotate(45deg);
  border-radius: 0px;
}
.cover {
  position: absolute;
  height: 20px;
  width: 30px;
  overflow: hidden;
  margin-left: -39px;
  margin-top: -3px;
}
/*Popup test End */

.form-area input,
.form-area select {
  background-color: #f9f9f9;
  font-size: 1.8rem;
  display: block;
  padding: 1.2rem;
  margin-bottom: 3rem;
  width: 45%;
  border-radius: 25px;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08);
  /* color: inherit; */
}
.form-area label {
  margin-bottom: 0.8rem;
}
.incident-submit-button,
.incident-back-button {
  padding: 1.2rem 2.4rem;
  width: 45%;
  color: #fff !important;
  background-color: #0066cb;
  font-size: 1.8rem;
  border: none;
  border-radius: 25px;
  margin-top: 2rem;
  cursor: pointer;
}
.incident-back-button {
  color: #0066cb !important;
  background-color: #fff;
  border: 1.5px solid #0066cb;
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}
.size {
  width: 45%;
}
.form-area .heading-secondary {
  margin-bottom: 1.2rem;
}

/* Challenge */
.challenge-pragraph {
  color: #666;
  font-size: 1.8rem;
  margin: 1.2rem 0;
  line-height: 1.45;
}
.challenge-heading {
  color: #444;
  font-size: 2rem;
  font-weight: 600;
}
.challenge-list {
  margin-top: 0.8rem;
  margin-bottom: 2rem;
  margin-left: 1.2rem;
  font-size: 1.8rem;
  color: #666;
  line-height: 1.55;
  list-style-image: url('../images/bullet1 (1).png');
}
.challenge-nested {
  margin-bottom: 2.5rem;
  margin-top: 1rem;
  margin-left: 1.2rem;
  list-style-image: url('../images/bullet2 (1).png');
}
.challenge-list li {
  margin: 10px 0;
}

/* Footer */
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 6.4rem;
  margin-bottom: 2.4rem;
}
.footer img {
  display: block;
}

/*******Media Queries *******/
@media (max-width: 69rem) {
  .form-area input,
  .form-area select,
  .icon-label {
    width: 60%;
  }
  .size {
    width: 60%;
  }
  .incident-submit-button,
  .incident-back-button {
    width: 60%;
  }
  .slider-container {
    width: 60%;
  }
  .issueGrid {
    padding-inline: 1rem;
    column-gap: 1rem;
  }
}
@media (max-width: 944px) {
  /* Home Screen */
  .mobileOnly {
    display: none;
  }
}
@media (max-width: 56rem) {
  .form-area input,
  .form-area select,
  .icon-label,
  .slider-container {
    width: 80%;
  }
  .size {
    width: 80%;
  }
  .incident-submit-button,
  .incident-back-button {
    width: 80%;
  }
  .incident-flex {
    gap: 2.4rem;
  }
  .container,
  .footer {
    padding: 0 4.2rem;
  }
  /* Home Screen */
  .mobile-report-incident-1:link,
  .mobile-report-incident-1:visited {
    display: inline-block;
    /* grid-row: 2; */
  }
  /* .grid-temp-2 {
    grid-row: 2;
  } */
}

@media (max-width: 44rem) {
  .form-area input,
  .form-area select,
  .icon-label,
  .slider-container {
    width: 100%;
  }
  .size {
    width: 100%;
  }
}

@media (max-width: 37.5rem) {
  .popup-hide {
    margin-top: 30px;
    width: 100%;
  }
  .arrow-left {
    display: none;
  }
  .buttonContainer {
    flex-direction: row-reverse;
    gap: 1rem;
  }
  .incident-flex {
    grid-template-columns: 1fr;
  }
  .grid-column-spread {
    grid-column: 1/1;
  }
  .info-container {
    grid-column: 1/1;
  }
  .rpa-info {
    grid-row: 3;
  }
  .ad {
    grid-row: 3;
    /* display: grid;
    grid-template-columns: 1fr; */
  }
  /* General */
  .heading-secondary {
    font-size: 2.6rem;
  }
  /* Home Screen */
  .homescreen-grid {
    grid-template-columns: 1fr;
  }
  /* Incident Form */
  .icon-label {
    font-size: 1.8rem;
  }
  .form-area input,
  .form-area select {
    font-size: 1.6rem;
  }
  /* Social Conatiner */
  .social-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .social-icons {
    margin-right: -10px;
  }
  .selectIssue {
    font-size: 2.1rem;
  }
  .popup:hover + .popup-hide {
    left: 0;
  }
}

@media (max-width: 35rem) {
  .container,
  .footer {
    padding: 0 2.4rem;
  }
  .footer {
    justify-content: center;
  }
  .issueIcon {
    width: 100px;
  }
}
@media (max-width: 393px) {
  .issueGrid {
    row-gap: 2rem;
    padding-inline: 0.5rem;
  }
}
